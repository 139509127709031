import { cn } from "~/lib/utils";

export function Badge({
  children,
  variant = "xs",
  className,
}: {
  children: React.ReactNode;
  variant?: "xs" | "sm";
  className?: string;
}) {
  return (
    <div
      className={cn(
        "rounded-lg border border-border bg-background font-medium text-muted-foreground",
        {
          "text-xs px-1.5 py-0.5": variant === "xs",
          "text-sm px-2 py-1": variant === "sm",
        },
        className
      )}
    >
      {children}
    </div>
  );
}
